import type {
  AoaInfo,
  AoaItem,
  AoaItemType,
  AoaProgressStatus,
  AoaSection,
  IterationUnavailableReason,
  KpiComparison,
} from '@packages/types'
import type { BaseTranslation } from 'typesafe-i18n'

type ActivityEntries =
  | 'createdAoa'
  | 'createdItem'
  | 'deletedAoa'
  | 'deletedItem'
  | 'createdCheckin'
  | 'approvedAoa'
  | 'updatedAoa'
  | 'closedAoa'
  | 'updatedItem'

const en_aoa = {
  aoa: {
    activity: {
      generalActivity: {
        createdAoa: 'Created new <></>.',
        createdItem: 'Created new {itemDescription:string} <></>.',
        deletedAoa: 'Deleted <></>.',
        deletedItem: 'Deleted {itemDescription:string} <></>.',
        createdCheckin: 'Created a new check-in for <></>.',
        approvedAoa: 'Approved <></>.',
        updatedAoa: 'Edited <></>.',
        closedAoa: 'Closed <></>.',
        updatedItem: 'Edited {itemDescription:string} <></>.',
      } satisfies Record<ActivityEntries, string>,
      scopedActivity: {
        createdAoa: 'Created this AOA.',
        createdItem: 'Created this {itemType:string}.',
        deletedAoa: 'Deleted this AOA.',
        deletedItem: 'Deleted this {itemType:string}.',
        createdCheckin: 'Created a new check-in.',
        approvedAoa: 'Approved this AOA.',
        updatedAoa: 'Edited this AOA.',
        closedAoa: 'Closed this AOA.',
        updatedItem: 'Edited this {itemType:string}.',
      } satisfies Record<ActivityEntries, string>,
      updatedAttributes: 'Updated: <></>.',
      updatedAttributesEntries: {
        comment: 'Comment',
        description: 'Description',
        text: 'Title',
        section: 'Section',
        results: 'Targets',
        responsibleUserId: 'Responsible Person',
        movedToAoaId: 'Moved to another AOA',
        periodEnd: 'End Date',
        periodStart: 'Start Date',
        itemType: 'Type',
        tags: 'Tags',
        iteration: 'Iteration',
        title: 'Title',
        status: 'Status',
      } satisfies Partial<Record<keyof AoaItem | keyof AoaInfo, string>>,
      activityTooltips: {
        allTagsRemoved: 'All tags removed',
      },
    },
    itemTypes: {
      action: 'Action',
      intent: 'Intent',
      outcome: 'Outcome',
      text: 'Text',
    } satisfies Record<AoaItemType, string>,
    metrics: {
      vsLastIteration: 'vs last iteration',
      executionProgress: 'Execution Progress',
      status: 'Status',
      confidenceLevel: 'Confidence Level',
      cancellation: 'Cancellation',
      noDataAvailable: 'No data available',
      completion: 'Completion',
      myCompletion: 'My Completion',
      resultCompletion: 'Result Completion Rate',
      myResultCompletion: 'My Effective Result Completion Rate',
      myConfidence: 'My Confidence',
      myExecutionProgress: 'My Execution Progress',
      myCancellation: 'My Cancellation',
      completedResults: 'Completed Results',
      totalResults: 'Total Results',
      sunburstChart: {
        companyStrategy: 'Company Strategy',
        colorBy: 'Color by',
        displayChartBy: 'Display chart by',
        byAoasActions: 'AOAs & Actions',
        byStrategy: 'Strategy',
      },
    },
    targets: {
      startValue: 'Start Value',
      targetValue: 'Target Value',
      currentValue: 'Current Value',
    },
    dashboard: {
      activityFeed: {
        cardHeader: 'Latest Updates',
        cardDescription: {
          active:
            'Your team has made {recentCount:number} updates in the last {days:number} days.',
          noRecent:
            'Your team has not made any updates in the last {days:number} days.',
        },
        userCardDescription: {
          active:
            '{userName:string} has made {recentCount:number} updates in the last {days:number} days.',
          noRecent:
            '{userName:string }team has not made any updates in the last {days:number} days.',
        },
        emptyState: {
          header: 'No recent activity',
          description: 'There are no recent actions or updates in this AOA.',
          descriptionUserActivity:
            "{userName:string} hasn't made any updates yet.",
        },
      },
      myActions: {
        cardHeader: 'My Core Activities',
        cardDescription: {
          due: 'You were assigned {totalCount:number} actions, and {dueCount:number} are awaiting your check-in.',
          noDue:
            'You were assigned {totalCount:number} actions. All actions have been checked in.',
          noActions: 'You have no actions assigned to you.',
        },
        checkinBadge:
          '{checkedinCount:number} / {totalCount:number} checked-in',
        emptyState: {
          header: 'No actions assigned',
          description:
            "You weren't assigned any actions to check in for this aoa.",
        },
      },
      userActions: {
        cardHeader: '{userName:string}’s Core Activities',
        cardDescription: {
          due: '{userName:string} was assigned {totalCount:number} actions, and {dueCount:number} are awaiting check-in.',
          noDue:
            '{userName:string} was assigned {totalCount:number} actions. All actions have been checked in.',
          noActions: '{userName:string} has no actions assigned.',
        },
        checkinBadge:
          '{checkedinCount:number} / {totalCount:number} checked-in',
        emptyState: {
          header: 'No actions assigned',
          description:
            "{userName:string} wasn't assigned any actions to check in for this aoa.",
        },
      },
      confidenceSunburst: {
        cardHeader: 'Confidence Level Distribution',
        cardDescription:
          'Confidence level of the outcomes and actions in the AOA.',
      },
      notificableOutcomes: {
        cardHeader: 'Notificable Outcomes',
        cardDescription:
          'Outcomes whose Confidence Level is less than 50% or are currently blocked will be shown here.',
        emptyState: {
          header: 'No notificable outcomes',
          description:
            'There are no outcomes that require your attention at the moment.',
        },
        badge: '{itemsAtRiskCount:number} outcome{{s}} at risk',
      },
    },
    buttons: {
      collapseItemCount: 'Show {0:number} item{{s}}',
      createNewAoa: 'Create New AOA',
      createRootAoa: 'Create Company AOA',
      newAoa: 'New AOA',
    },
    checkin: {
      commentStep: 'Do you have any comments or notes to add?',
      comperatorTexts: {
        labels: {
          equal: 'Equal to',
          greaterThan: 'Greater than',
          greaterThanOrEqual: 'Greater than or equal to',
          lessThan: 'Less than',
          lessThanOrEqual: 'Less than or equal to',
        } satisfies Record<KpiComparison, string>,
        targetValueOnly: {
          prefix: {
            equal: 'Result equals ',
            greaterThan: 'Result is greater than ',
            greaterThanOrEqual: 'Result is greater than or equal to ',
            lessThan: 'Result is less than ',
            lessThanOrEqual: 'Result is less than or equal to ',
          } satisfies Record<KpiComparison, string>,
          suffix: {
            equal: '',
            greaterThan: '',
            greaterThanOrEqual: '',
            lessThan: '',
            lessThanOrEqual: '',
          } satisfies Record<KpiComparison, string>,
        },
        startAndTargetValue: {
          prefix: {
            equal: 'Result starts at ',
            greaterThan: 'Result starts at ',
            greaterThanOrEqual: 'Result starts at ',
            lessThan: 'Result starts at ',
            lessThanOrEqual: 'Result starts at ',
          } satisfies Record<KpiComparison, string>,
          suffix: {
            equal: ' and is expected to reach ',
            greaterThan: ' and is expected to exceed ',
            greaterThanOrEqual: ' and is expected to reach or exceed ',
            lessThan: ' and is expected to be less than ',
            lessThanOrEqual: ' and is expected to be less than or equal to ',
          } satisfies Record<KpiComparison, string>,
        },
        startValueOnly: {
          prefix: {
            equal: 'Result starts at ',
            greaterThan: 'Result starts at ',
            greaterThanOrEqual: 'Result starts at ',
            lessThan: 'Result starts at ',
            lessThanOrEqual: 'Result starts at ',
          } satisfies Record<KpiComparison, string>,
          suffix: {
            equal: '',
            greaterThan: ' and is expected to increase',
            greaterThanOrEqual: ' and is expected to increase',
            lessThan: ' and is expected to decrease',
            lessThanOrEqual: ' and is expected to decrease',
          } satisfies Record<KpiComparison, string>,
        },
      },
      expectedResult: 'Expected Result',
      confidenceSlider: {
        balanced: 'Balanced',
        certain: 'Certain',
        notConfident: 'Not confident',
        quiteConfident: 'Quite Confident',
        somewhatUncertain: 'Somewhat uncertain',
      },
      fulfillmentStates: {
        failed: 'The task has been unsuccessful in achieving its objectives.',
        completed: 'The task is complete, and all targets have been reached.',
        unknown: 'The task is in progress, and the outcome is uncertain.',
        failedTitle: 'Failed',
        completedTitle: 'Completed',
        unknownTitle: 'In Progress',
      },
      confidenceStep: 'How confident are you in fully delivering on time?',
      createFirstCheckin: 'Create First Check-In',
      due: 'Due',
      emptyCheckinList:
        'You have no items assigned to you that require a check-in.',
      headline: 'Status Check-In',
      latestCheckin: 'Latest Check-In',
      latestVersion: 'Latest Version',
      newCheckin: 'New Check-In',
      noCheckinsHistorical: 'No Check-Ins',
      progressStatusStep: 'What is the current status of this item?',
      resultStep: "What's your progress on the results?",
      percentualProgressStep:
        'How much of the needed work has already been completed?',
      subheader: 'Check-in on your outcomes and results',
    },
    common: {
      accountable: 'Accountable',
      accountableTeam: 'Accountable Team',
      actions: 'Actions',
      activeAoas: 'Active AOAs',
      draftAoas: 'Draft AOAs',
      alreadyImported: 'Already imported',
      aoaTitle: 'AOA Title',
      approver: 'Approver',
      archivedAoas: 'Archived AOAs',
      checkin: 'Status Check-in',
      showMetrics: 'Show Metrics',
      confidence: 'Confidence',
      emptyIntents: 'No intents have been added yet.',
      emptyLooseOutcomes: 'No loose outcomes have been added yet.',
      emptySharedReality: 'No shared reality and knowledge has been added yet.',
      higherIntents: 'Higher Intents',
      inherted: 'Inherited',
      intents: 'Intents',
      itemHistory: 'Item History',
      iteration: 'Iteration',
      noIteration: 'No iteration',
      outcome: 'Outcome',
      outcomes: 'Outcomes',
      parentAoa: 'Parent AOA',
      progress: 'Progress',
      responsible: 'Responsible',
      result: 'Result',
      selectedIteration: 'Assigned Iteration',
      strategicContext: 'Shared Reality and Knowledge',
      text: 'Text',
      title: 'Title',
      untitledAoa: 'Untitled AOA',
      actionAchievment: 'Action Achievement',
      latestCheckinComment: 'Latest Check-In Comment',
    },
    details: {
      pageHeader: 'AOA Details',
      pageSubheader: 'View and manage an AOA',
    },
    filterPage: {
      cardHeader: 'All Intents, Outcomes, Actions & Results',
      lastCheckinRangeLabel: 'Last Check-in',
      noResultsDescription: 'Your applied filters returned no results.',
      pageHeader: 'AOA Dashboard',
      pageSubheader: 'Filter and view AOAs and their items',
    },
    forms: {
      requiredField: 'Required',
      accountableRequired: 'Accountable person is required',
      accountableTeamRequired: 'Accountable team is required',
      resultRequired: 'Result is required',
      add: {
        action: 'Add action…',
        higherIntent: 'Add higher intent…',
        intent: 'Add intent…',
        outcome: 'Add outcome…',
        text: 'Add item…',
      } satisfies Record<AoaItemType, string> & Record<string, string>,
      booleanTypeLabel: 'Fulfilled or not fulfilled',
      confidenceRequired: 'Confidence is required',
      iterationRequired: 'Iteration is required',
      numericTypeLabel: 'Measurable Progress',
      outcomeType: 'Outcome Type',
      periodEnd: 'End Date',
      periodEndRequired: 'End date is required',
      periodStart: 'Start Date',
      periodStartRequired: 'Start date is required',
      plannedQuantifier: 'Planned Quantifier',
      plannedStartingQuantifier: 'Starting Planned Quantifier',
      responsibleRequired: 'Responsible person is required',
      selectResponsible: 'Select responsible…',
      textRequired: 'Text is required',
      titleRequired: 'Title is required',
      removeResult: 'Remove Result',
      addResult: 'Add Result',
      addKpi: 'Add Key Performance Indicator',
      comperator: 'Comparison',
      description: 'Description',
      referenceLink: 'Reference Link',
    },
    import: {
      exitImport: 'Exit Import',
      importButtonLabel: 'Import {0:number} item{{s}}',
      importButtonLabelEmpty: 'Select items to import',
      importCardHeadline: 'Import from Previous Iteration	',
      importCardSubheadline:
        'Items can either moved or duplicated from the previous iteration. Moved items are linked to the previous iteration and will show changes made in the previous iteration. Duplicated items are independent of the previous iteration.',
      importFromPreviousIteration: 'Import from previous iteration',
      importPreviousAssignedHeadline: 'Previously assigned items',
      showAllAvailableItems: 'Show all {status:string} items',
      hideAllAvailableItems: 'Hide all {status:string} items',
      noPreviousAssignedItemsAvailable:
        'There are no items available to import from the previous iteration.',
      importFromPreviousIterationAction:
        'Import Action from previous iteration',
      importFromPreviousIterationIntent:
        'Import Intent from previous iteration',
      importFromPreviousIterationIntentAction:
        'Import Intent/Action from previous iteration',
      importFromPreviousIterationLooseOutcome:
        'Import Loose Outcome from previous iteration',
      importFromPreviousIterationRootIntent:
        'Import Higher Intent from previous iteration',
      importItemsFromLabel: 'Import items from…',
      importModeSelector: 'How should the items be imported?',
      importModeSelectorDescription:
        'Select whether to duplicate or move the items.',
      importModeSelectorDuplicate: 'Duplicate',
      importModeSelectorMove: 'Move',
      importModeSelectorDuplicateDescription:
        'Actions will be copied without reference to the previous Action. Previous check-ins will not be linked.',
      importModeSelectorMoveDescription:
        'Actions will be moved including reference and check-ins to the previous Action.',
      importModeSelectorMoveSelected: 'Moving items from previous iteration',
      importModeSelectorDuplicateSelected:
        'Duplicating items from previous iteration',
      importNoItemsAvailable: 'Nothing to import',
      importNoItemsAvailableParentAoaDescription:
        'It seems that there are no items available to import from the parent AOA.',
      importNoItemsAvailablePreviousIterationDescription:
        'It seems that there are no items available to import from the previous iteration.',
      importSectionCardHeadline: 'Import „{section:string}“',
      importSuccessMessage: 'Successfully imported {0:number} item{{s}}',
      movedToNextIterationDescription: 'Moved to next iteration',
      movedToNextIterationShort: 'moved',
    },
    infos: {
      confirmApproveAoa: 'This will take the AOA out of the draft state.',
      confirmApproveAoaDescription:
        'Once approved, no items can be added or deleted.',
      withdrawAoa: 'This will close {aoaName:string}.',
      withdrawAoaDescription:
        'Once closed, users can no longer create check-ins.',
      confirmDeleteAoa: "This will delete the AOA and all it's items.",
      confirmMultipaste: 'This will add multiple items at once.',
      confirmTreeDelete:
        'This may result in the deletion of items below this one, which may have an impact on other AOAs.',
      dragToReorder: 'Drag to reorder',
      enterOrDetails: 'Press enter to add item, or provide more details.',
      enterOrPaste: 'Press enter to add item, or paste multiple items at once.',
      outcomeTypeBoolean: 'The outcome can be either Yes or No.',
    },
    iterationUnavailableReasons: {
      'aoa-cycle-contained-by-existing':
        'The AOA already has a cycle that fully contains the iteration.',
      'aoa-cycle-exists': 'The AOA already has a cycle for the period.',
      'parent-aoa-not-approved': 'The parent AOA is not approved.',
    } satisfies Record<IterationUnavailableReason, string>,
    menu: {
      addAction: 'Add Action',
      addChildAoa: 'Create New Sub AOA',
      addIteration: 'Create New Iteration',
      checkin: 'Create Check-in',
      withdraw: 'Close AOA',
      delete: 'Delete',
      deleteAoa: 'Delete AOA',
      edit: 'Edit Details',
      renameAoa: 'Rename AOA',
      history: 'Show History',
      viewParentAoa: 'View Parent AOA',
      editContent: 'Edit Content',
      dangerZone: 'Danger Zone',
      newAoa: 'Create new AOAs',
    },
    deleteItem: {
      deleteModalTitle: 'Are you sure you want to delete this item?',
    },
    newAoa: {
      createNewIteration: 'Create New Iteration',
      emptyStates: {
        create: {
          company: 'Create a company AOA to get started.',
          department: 'Create your first Department AOA',
          team: 'Create your first Team AOA',
        },
        emptyContextInheritanceNotice:
          "If no items are added, the items of the parent AOA's iteration will be inherited.",
        noPermissionsToCreate: {
          company: 'No Company AOA was created yet',
          department: 'No Department AOA was created yet',
          team: 'No Team AOA was created yet',
        },
        setupOrganization: 'Setup Organization',
        unavailable: {
          company: 'Setup your organization first',
          department: 'Create a company AOA first',
          team: 'Create a department AOA first',
        },
      },
      failedToCreateAoa: 'Failed to create AOA',
      iterationSourceLabel: 'Pick an AOA to create a new iteration',
      metadataPanelHeader: 'Title, Iteration & Team',
      newAoaDescription:
        'Create a new blank AOA. Pick a parent AOA and a period to get started.',
      newAoaLabel: 'New AOA',
      newIterationDescription:
        'Add a new iteration to an existing AOA. This will create a new version of the AOA for a new period.',
      newIterationLabel: 'New Iteration',
      pageSubheader:
        'Create a new AOA or add a new iteration to an existing one',
      parentAoa: 'Parent AOA of {0:string}',
      parentSourceLabel: 'Select the Parent AOA',
      pickAoaLabel: 'Pick an AOA',
      pickParentAoaLabel: 'Pick a parent AOA',
      previousIterationCycle: 'Previous Iteration',
      selectAoaPlaceholder: 'Select AOA…',
      selectAoaType: 'Select AOA Type',
      selectParentAoaIteration: 'Select Parent AOA Iteration',
    },
    overview: {
      emptyAoaList: "Your organization doesn't have any AOAs yet.",
      pageHeader: 'AOA Overview',
      pageSubheader: "View and manage the organization's AOAs",
    },
    progress: {
      blocked: 'Blocked',
      cancelled: 'Cancelled',
      delayed: 'Delayed',
      done: 'Finished',
      'on track': 'On track',
      open: 'open',
    } satisfies Record<AoaProgressStatus | 'open', string>,
    yesNoOutcome: {
      completed: 'Completed',
      failed: 'Failed',
    },
    progressDescription: {
      blocked: 'External factors are preventing you from making progress.',
      cancelled:
        'The outcome is no longer relevant. You will no longer receive reminders',
      delayed: 'You’re making progress, but you’re behind schedule.',
      done: 'All planned activities have been conducted. You will no longer receive reminders.',
      'on track': 'You are making progress as planned.',
    } satisfies Record<AoaProgressStatus, string>,
    sectionHelpers: {
      beliefs:
        'What must be true for you to complete your mission? What are your major hypotheses for success?',
      boundaries:
        'What must not occur as a side effect? What is not covered by the scope?',
      challenges:
        'Identify potential difficulties or complications and identify challenges you are aware of.',
      higherIntents: 'What are the higher intents that guide your strategy?',
      input: 'What do you need to accomplish your mission?',
      intentsActions:
        'What do you really, really, really want to accomplish from now until the conclusion of the period?',
      looseOutcomes: 'What are the outcomes and results you want to achieve?',
      mission:
        'How are you going to bring your vision to life? Describe your desired future state of success in the next three years or more.',
      statusQuo: 'Where are we today? Describe the current situation.',
      vision:
        'What motivates you to get out of bed in the morning? Your northern star?',
    } satisfies Record<AoaSection, string> & Record<string, string>,
    sections: {
      beliefs: 'Beliefs',
      boundaries: 'Boundaries',
      challenges: 'Challenges',
      higherIntents: 'Higher Intents',
      input: 'Input',
      intentsActions: 'Strategy Alignment & Commitment',
      looseOutcomes: 'Targets',
      mission: 'Mission',
      statusQuo: 'Status Quo',
      vision: 'Vision',
    } satisfies Record<AoaSection, string> & Record<string, string>,
    tabSections: {
      intentsActions: 'Intents & Actions',
      outcomesResults: 'Targets',
      sharedReality: 'Shared Reality',
      overview: 'Overview',
      strategy: 'Strategy',
      teamwork: 'Teamwork',
      visionMissionHigherIntents: 'Vision, Mission & Higher Intents',
    },
    tooltips: {
      action:
        'Describes the detailed steps that needs to be done in order to achieve the related Intent. Every Action shall describe concrete jobs and provide at least one qualitative outcome or quantitative result.',
      higherIntent:
        'The most important and specific achievement that you the parent department want to reach in the next 12 months starting now.',
      intent:
        'Describes the most important and specific achievement that you want to reach in the next 12 months starting now. It defines only what you can directly influence and contribute to realize the dedicated Higher Intent. It is the specific guidance that doesn’t have to be measurable. (Think in right directions not tasks or results.)',
      movedFromPreviousIterationCount:
        'Moved {0:number} time{{s}} from previous iteration{{s}}',
      outcome:
        'Each Action requires at least one qualitative outcome or one quantitative result. For outcome definition think in "Definition of Done". For results, think in the ambition level and the right metric. Results and outcomes can be bound to an Action or an AOA.',
      rootIntent:
        'Describes what you want to achieve, through defining the most important strategic pillars of the company’s mission for the next 3 years. It provides guidance for focused strategic execution.',
    },
    tree: {
      confidenceColHeader: 'Confidence',
      lastCheckinColHeader: 'Last Check-In',
      progressColHeader: 'Progress/Status',
    },
  },
} as const satisfies BaseTranslation

export default en_aoa
